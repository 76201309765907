import { Select } from "@sumup/circuit-ui";
import { Customize, Moon, Sun } from "@sumup/icons";
import { useEffect, useState } from "react";

import styles from "./styles.module.css";

type ColorScheme = "system" | "light" | "dark";

function isColorScheme(colorScheme: unknown): colorScheme is ColorScheme {
  // @ts-expect-error This works even if `colorScheme` isn't a string
  return ["light", "dark", "system"].includes(colorScheme);
}

const icons = {
  system: Customize,
  light: Sun,
  dark: Moon,
};

const options = [
  {
    label: "Auto",
    value: "system",
  },
  {
    label: "Light",
    value: "light",
  },
  {
    label: "Dark",
    value: "dark",
  },
];

const defaultColorScheme = "system";

export function ColorSchemeSelect() {
  const [colorScheme, setColorScheme] = useState<ColorScheme>(defaultColorScheme);

  useEffect(() => {
    const preferredColorScheme = typeof localStorage !== "undefined" && localStorage.getItem("color-scheme");

    return setColorScheme(isColorScheme(preferredColorScheme) ? preferredColorScheme : defaultColorScheme);
  }, []);

  const handleChange = (newColorScheme: ColorScheme) => {
    setColorScheme(newColorScheme);
    document.documentElement.dataset.colorScheme = newColorScheme;
    localStorage.setItem("color-scheme", newColorScheme);
  };

  const prefix = icons[colorScheme];

  return (
    <Select
      className={styles.select}
      label="Theme"
      hideLabel
      options={options}
      value={colorScheme}
      renderPrefix={prefix}
      onChange={(event) => {
        handleChange(event.target.value as ColorScheme);
      }}
    />
  );
}
